<template>
  <gf-card>
    <gf-content v-loading="loading" overflow>
      <template #title>
        <div class="title">
          <span class="font-weight-bold">Invoice #{{ si.si_number }}</span>
        </div>
      </template>
      <template #toolbar>
        <el-button size="small" @click="print">Print</el-button>
        <el-button-group v-if="!si.is_paid" style="margin-left: 10px">
          <el-button size="small" @click="edit">Edit</el-button>
          <el-button :disabled="si.is_packed ? true : false" size="small" @click="remove">Delete</el-button>
        </el-button-group>
        <el-popover v-if="!si.is_paid" placement="bottom-end" popper-class="popper" trigger="click" width="300">
          <div class="popper__content">
            <div class="popper__content__header">Create Payment</div>
            <div class="popper__content__body">
              <el-form ref="payment" :model="payment" :rules="paymentRules" label-width="130px" size="small">
                <el-form-item label="Payment Type" prop="payment_method_id">
                  <el-select v-model="payment.payment_method_id">
                    <el-option v-for="pm in paymentMethods" :key="pm.id" :label="pm.name" :value="pm.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Amount" prop="amount">
                  <el-input v-model="payment.amount"></el-input>
                </el-form-item>
                <el-form-item label="Paid On" prop="paid_on">
                  <el-date-picker v-model="payment.paid_on" format="dd MMM yyyy" size="small" style="width: 100%;"
                    type="date" value-format="yyyy-MM-dd">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="Reference" prop="reference">
                  <el-input v-model="payment.reference"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="popper__content__footer">
              <el-button size="small" @click="createPayment">Create Payment</el-button>
            </div>
          </div>
          <el-button slot="reference" size="small" style="margin-left: 10px" @click="clickPay">Pay</el-button>
        </el-popover>
      </template>
      <template #header>
        <el-row>
          <el-col :lg="9" :md="12" :sm="24" :xl="9">
            <div class="detail">
              <div class="title">
                <span class="font-size-sm font-weight-normal pr-3">Bill To</span>
                <span class="font-size-sm font-weight-normal pr-3">Ship To</span>
              </div>
              <div class="content">
                <span class="font-size-sm pl-3">{{ si.bill_to.label }}</span>
                <span class="font-size-sm pl-3">{{ si.ship_to.label }}</span>
              </div>
            </div>
          </el-col>
          <el-col :lg="9" :md="12" :sm="24" :xl="9">
            <div class="detail">
              <div class="title">
                <span class="font-size-sm font-weight-normal pr-3">Terms</span>
                <span class="font-size-sm font-weight-normal pr-3">Issued</span>
                <span class="font-size-sm font-weight-normal pr-3">Payment Due</span>
              </div>
              <div class="content">
                <span class="font-size-sm pl-3">{{ si.payment_term.name }}</span>
                <span class="font-size-sm pl-3">{{ $DateFormat(si.issued_date) }}</span>
                <span class="font-size-sm pl-3">{{ $DateFormat(si.payment_due) }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </template>
      <div class="table">
        <gf-table :data="si.items" :scrollable="false">
          <el-table-column label="Item Name" min-width="200">
            <template slot-scope="slot">
              <div class="gf-item">
                <div class="gf-item__name">
                  <span v-if="slot.row.product.name === slot.row.variant.name && slot.row.variant.is_pack === 0">{{
                    slot.row.variant.name }}</span>
                  <span v-else-if="slot.row.variant.is_pack === 0">{{ slot.row.product.name }} - {{ slot.row.variant.name
                  }}</span>
                  <span v-else>
                    <span v-if="slot.row.product.name === slot.row.variant.variant.name">{{ slot.row.variant.variant.name
                    }} - {{ slot.row.variant.name }}</span>
                    <span v-else>{{ slot.row.product.name }} - {{ slot.row.variant.variant.name }} - {{
                      slot.row.variant.name }}</span>
                    <span v-if="slot.row.variant.is_pack === 1"> (Pack of {{ slot.row.variant.pack_size }})</span>
                  </span>
                </div>
                <div class="gf-item__sub">
                  <span class="gf-item__sub__sku">SKU: {{ slot.row.variant.sku }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Qty Invoice" min-width="120">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.quantity) }}
            </template>
          </el-table-column>
          <el-table-column label="Not Invoice" min-width="120">
            <template slot-scope="slot">
              {{ $NumberFormat(parseFloat(slot.row.total_quantity) - parseFloat(slot.row.order_item.invoiced)) }}
            </template>
          </el-table-column>
          <el-table-column label="Total Qty" min-width="120">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.total_quantity) }}
            </template>
          </el-table-column>
          <el-table-column label="Total" min-width="100" prop="total">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.cost * slot.row.quantity - (slot.row.cost * slot.row.quantity * (slot.row.discount
                / 100))) }}
            </template>
          </el-table-column>
        </gf-table>
      </div>
      <div class="footer mt-2">
        <div class="note">
          <span>{{ si.message }}</span>
        </div>
        <div class="gf-summary">
          <ul class="summary">
            <li class="summary__item">
              <span class="text">Total Units</span>
              <span class="value">{{ $NumberFormat(si.units) }}</span>
            </li>
            <li v-if="si.is_inclusive !== 2" class="summary__item">
              <span class="text">Subtotal</span>
              <span class="value">{{ $NumberFormat(si.subtotal) }}</span>
            </li>
            <div v-if="si.is_inclusive !== 2">
              <li v-for="(vat, index) in si.vats" :key="index" class="summary__item">
                <span class="text">{{ (si.is_inclusive) ? 'Including' : 'Plus' }} VAT ({{ vat.rate }}%)</span>
                <span class="value">{{ $NumberFormat(vat.value) }}</span>
              </li>
            </div>
            <li class="summary__item">
              <span class="text">Total</span>
              <span class="value">{{ $NumberFormat(si.total) }}</span>
            </li>
          </ul>
        </div>
      </div>
    </gf-content>
  </gf-card>
</template>

<style lang="scss" scoped>
.popper {
  .el-form-item {
    width: 100%;
    margin-bottom: 8px;
  }

  &__content {
    padding: 10px 10px 0 10px;

    &__header {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 15px;
    }

    &__footer {
      margin-top: 15px;
      text-align: center;
    }
  }
}

.detail {
  display: flex;
  justify-content: center;

  .title,
  .content {
    display: flex;
    flex-direction: column;
  }

  .title {
    align-items: flex-end;

    span {
      border-right: 1px solid #ebedf3;
    }
  }

  .content {
    align-items: flex-start;
  }
}

.footer {
  display: flex;
  justify-content: space-between;

  .note {
    width: 500px;
  }
}
</style>

<script>
// Services
import SalesOrderService from '@/services/v1/SalesOrder'
import SalesInvoiceService from '@/services/v1/SalesInvoice'
import SalesPaymentService from '@/services/v1/SalesPayment'
import PaymentMethodService from '@/services/v1/PaymentMethod'

export default {
  props: {
    title: String,
    updateChild: Boolean
  },
  data() {
    return {
      loading: false,

      so: {
        so_number: null
      },
      si: {
        si_number: null,
        issued_date: null,
        payment_due: null,
        is_inclusive: 0,
        message: null,
        units: null,
        subtotal: null,
        vats: [],
        total: null,
        is_paid: null,
        is_packed: null,
        paid: null,
        items: [],
        bill_to: {
          label: null
        },
        ship_to: {
          label: null
        },
        ship_from: {
          name: null
        },
        payment_term: {
          name: null
        }
      },
      payment: {
        sales_order_id: null,
        sales_invoice_id: null,
        si_number: null,
        payment_method_id: null,
        amount: null,
        paid_on: this.$DateISOFormat(new Date().toISOString()),
        reference: null
      },
      paymentMethods: [],

      // rules
      paymentRules: {
        payment_method_id: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        paid_on: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        amount: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    updateChild: function () {
      if (this.updateChild) {
        this.getSalesInvoice()
        this.$emit('update:updateChild', false)
      }
    },
    $route(to, from) {
      if (to !== from) {
        this.loadPage()
      }
    }
  },
  methods: {
    // handler
    print() {
      this.$router.push({ name: 'invoice-print', params: { id: this.$route.params.id, iid: this.$route.params.iid } })
    },
    edit() {
      this.$router.push({ name: 'invoice-edit', params: { id: this.$route.params.id, iid: this.$route.params.iid } })
    },
    remove() {
      this.$confirm('You are attempting to delete this Invoice. This action cannot be undone.', 'Are you sure?', {
        confirmButtonText: 'Yes, Delete Invoice',
        cancelButtonText: 'Cancel'
      }).then(async () => {
        try {
          const siService = new SalesInvoiceService(this.$route.params.id, this.$route.params.iid)
          await siService.del()
          this.$message.success(`${this.si.si_number} successfully deleted`)
          this.$emit('update:update', true)
          this.$router.push({ name: 'invoice-list', params: { id: this.$route.params.id } })
        } catch (error) {
          this.$Error(error)
        }
      }).catch(() => { })
    },
    clickPay() {
      this.payment.amount = parseFloat(this.si.total) - parseFloat(this.si.paid)
    },
    createPayment() {
      this.$refs.payment.validate()
        .then(async () => {
          try {
            const payment = this.$_.cloneDeep(this.payment)

            payment.sales_order_id = parseInt(this.$route.params.id)
            payment.sales_invoice_id = parseInt(this.$route.params.iid)
            payment.si_number = this.si.si_number

            this.$Sanitize(payment)

            const spService = new SalesPaymentService(this.$route.params.id)
            await spService.create(payment)
            this.$message.success(`${this.si.si_number} was successfully paid`)

            this.$emit('update:update', true)
            this.getSalesInvoice()
          } catch (error) {
            this.$Error(error)
          }
        })
        .catch(() => { })
    },
    // Service Fetch
    async getSalesOrder() {
      try {
        const soService = new SalesOrderService(this.$route.params.id)
        const result = await soService.getSOandStatus()
        this.so = result.data
      } catch (error) {
        this.$Error(error)
      }
    },
    async getSalesInvoice() {
      try {
        this.loading = true
        const siService = new SalesInvoiceService(this.$route.params.id, this.$route.params.iid)
        const result = await siService.get()
        this.si = result.data
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },
    async getPaymentMethods() {
      try {
        const pmService = new PaymentMethodService()
        const result = await pmService.list()
        this.paymentMethods = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },

    // helper
    async loadPage() {
      await this.getSalesOrder()
      await this.getSalesInvoice()

      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Sales Order', route: '' },
        { title: 'Order' },
        { title: this.so.so_number },
        { title: 'Invoices' },
        { title: this.si.si_number }
      ])

      this.$emit('update:title', this.si.si_number)
    }
  },
  async mounted() {
    this.loading = true
    await this.loadPage()
    await this.getPaymentMethods()
  }
}
</script>
